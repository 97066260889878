import { useEffect, useState } from 'react';
import FAQ from "../components/FAQ";

// Countdown-Komponente
const Countdown = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const difference = targetDate - now;

            if (difference > 0) {
                setTimeLeft({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60),
                });
            } else {
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        const interval = setInterval(calculateTimeLeft, 1000);
        return () => clearInterval(interval);
    }, [targetDate]);

    const formatTime = (time) => String(time).padStart(2, '0');

    return (
        <div className="flex justify-center py-6">
            <div className="grid grid-flow-col gap-6 text-center bg-base-300 p-6 rounded-md shadow-md max-w-screen-lg">
                {['days', 'hours', 'minutes', 'seconds'].map((unit, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <span className="countdown font-mono text-5xl">{formatTime(timeLeft[unit])}</span>
                        <span className="text-lg font-semibold">{unit}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

// BlogPost-Komponente für die Anzeige des Blog-Eintrags
const BlogPost = ({ title, text, publishedAt, creator, imageUrl }) => (
    <div className="blog-post my-6 p-6 bg-base-300 shadow-md mx-auto rounded-md w-full max-w-screen-xl">
        {imageUrl && (
            <img
                src={imageUrl}
                alt={title}
                className="w-full object-cover rounded-md mb-4"
            />
        )}
        <p className="text-lg text-secondary mb-2 font-bold">{new Date(publishedAt).toLocaleDateString()}</p>
        <h2 className="text-3xl font-bold mb-4">{title}</h2>
        <p className="text-lg mb-4 leading-relaxed">{text}</p>
        <p className="text-lg font-bold text-secondary">~ {creator}</p>
    </div>
);

// HomePage-Komponente
const HomePage = () => {
    const targetDate = new Date('2024-11-29T20:00:00');
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const fetchLatestBlog = async () => {
            try {
                const response = await fetch('https://admin.ttv-moshpit.de/api/blogs?sort[0]=publishedAt:desc&pagination[limit]=1&populate=*');
                if (!response.ok) throw new Error(`Error: ${response.status}`);
                const data = await response.json();

                if (data && data.data && data.data.length > 0) {
                    const latestBlog = data.data[0].attributes;
                    setBlog({
                        title: latestBlog.Title,
                        text: latestBlog.Text,
                        publishedAt: latestBlog.Datum,
                        creator: latestBlog.Ersteller || 'Unknown',
                        imageUrl: latestBlog.Bild?.data?.attributes?.url
                            ? `https://admin.ttv-moshpit.de${latestBlog.Bild.data.attributes.url}`
                            : '',
                    });
                }
            } catch (error) {
                console.error('Error fetching blog post:', error.message);
            }
        };

        fetchLatestBlog();
    }, []);

    return (
        <div className="container mx-auto px-4 py-8">
            {/* Countdown-Komponente */}
            <Countdown targetDate={targetDate} />

            {/* BlogPost-Komponente */}
            {blog ? (
                <BlogPost
                    title={blog.title}
                    text={blog.text}
                    publishedAt={blog.publishedAt}
                    creator={blog.creator}
                    imageUrl={blog.imageUrl}
                />
            ) : (
                <p className="text-center text-lg font-medium text-secondary mt-8">Lade neusten News Beitrag...</p>
            )}


            <div className="divider w-full "></div>
                <FAQ/>
        </div>
    );
};

export default HomePage;