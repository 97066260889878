import { FaDownload } from "react-icons/fa";

import background from '../assets/summer_moshpit_map_background.png';

const Downloads = () => {

  const handleDownloadClick = () => {
    window.location.href = "http://185.223.30.27/download/Summer-Moshpit2024_Map.zip";
};

    return (
    <div className="flex flex-col items-center p-6">
        
        <h1 className="text-5xl font-bold text-center mt-2 mb-7">DOWNLOADS</h1>
<div className="card lg:card-side bg-base-300 shadow-xl w-full max-w-screen-xl mx-auto mb-6 rounded-md">
  <figure className="basis-3/5">
    <img
      src={background}
      alt="Album" />
  </figure>
  <div className="card-body">
    <h2 className="card-title text-secondary">Summer Moshpit 2024</h2>
    <p> <h3 className="">Lade dir die exklusive Summer Moshpit 2024 Map herunter.</h3>
    <br />
        <h4 className="font-bold text-secondary">Installation:</h4>
        1. Lade die Map-Datei herunter. <br />
        2. Entpacke die Datei in deinen Minecraft-Welten-Ordner.<br />
        3. Starte Minecraft und wähle die Summer Moshpit 2024-Map aus.</p>
    <div className="card-actions justify-end">
    <button 
                        onClick={handleDownloadClick}
                        className="btn bg-secondary hover:bg-base-200 rounded-md flex items-center gap-2"
                    >
                        <FaDownload />DOWNLOAD
                    </button>
      
    </div>
  </div>
</div>
</div>
    );
}
export default Downloads