import {Outlet} from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Snowfall from "react-snowfall";
import snowflake from '../assets/snowflake.png'

const snowflakeImg = document.createElement('img')
snowflakeImg.src = snowflake;

const images = [snowflakeImg]

const Layout = () => {
    return (
        <>
            <Snowfall snowflakeCount={30} speed={[0.5, 2]} wind={[-0.5, 2]} radius={[5, 20]} rotationSpeed={[-1, 0.5]} images={images} style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                zIndex: '1'
            }}/>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout;