import FAQElement from "./FAQElement";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";

const MessageRenderer = ({ message }) => {
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;

    const parseMessage = (text) => {
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = linkRegex.exec(text)) !== null) {
            const [fullMatch, linkText, linkUrl] = match;
            const startIndex = match.index;

            if (startIndex > lastIndex) {
                parts.push(text.slice(lastIndex, startIndex));
            }

            parts.push(
                <Link key={linkUrl} to={linkUrl} target="_blank" rel="noopener noreferrer" className="text-secondary hover:underline">
                    {linkText}
                </Link>
            );

            lastIndex = linkRegex.lastIndex;
        }

        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }

        return parts;
    };

    return parseMessage(message);
};

const FAQ = () => {

    const [faqs, setFAQs] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchFAQ = async () => {
        try {
            const response = await axios.get('https://admin.ttv-moshpit.de/api/faqs?populate=*');
            const responseData = response.data;

            if (responseData && responseData.data) {
                const faqData = responseData.data.map((faq) => ({
                    id: faq.id,
                    question: faq.attributes.Frage,
                    answer: faq.attributes.Antwort
                }));

                setFAQs(faqData);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching posts:', error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFAQ();
    });

    return (
        <div className="my-6 p-6 bg-base-300 shadow-md mx-auto rounded-md w-full max-w-screen-xl">
            <h1 className="text-5xl font-bold text-center mt-2 mb-7">FAQ</h1>
            {loading ? (
                <p className="text-center text-gray-400">Lädt...</p>
            ) : (
                faqs.map((faq) => (
                    <FAQElement question={faq.question} answer={<MessageRenderer message={faq.answer}/>}/>
                ))
            )}
        </div>
    );
}

export default FAQ;