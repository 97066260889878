const FAQElement = ({ question, answer }) => {
    return (
        <div className="collapse collapse-plus bg-base-200 mb-3">
            <input type="radio" name="faq"/>
            <div className="collapse-title text-xl font-bold">{question}</div>
            <div className="collapse-content">
                <p>{answer}</p>
            </div>
        </div>
    );
}

export default FAQElement;