import React, { useState } from 'react'


import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";





const Builder = () => {

  const [formData, setFormData]= useState({
    data: {
      MinecraftName: "",
        Discord: "",
        Bewerbung: "",
    },
    error: {},
  });

  const sendEmail = async () => {

    const { EMail, MinecraftName, Discord, Bewerbung, AngabenBestätigt, DatenschutzAkzeptiert} = formData.data;

    try {
      const response = await fetch('https://admin.ttv-moshpit.de/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          EMail: EMail,
          MinecraftName: MinecraftName,
          Discord: Discord,
          Bewerbung: Bewerbung,
          Bewerbungsart: "Builder",
          AngabenBestätigt: AngabenBestätigt,
          DatenschutzAkzeptiert: DatenschutzAkzeptiert
        }),
      });
  
      if (response.ok) {
        console.log("Email sent successfully!");
      } else {
        console.error("Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };



  const handleCheckboxChange = (fieldName) => {
    setFormData((prevFormData) => ({
      data: {
        ...prevFormData.data,
        [fieldName]: !prevFormData.data[fieldName],
      },
      error: {}
    }));
  };

    return (

      <div className="flex flex-col items-center w-full max-w-screen-xl mx-auto">
        <h1 className=" text-5xl font-bold text-center mt-8 mb-2">BUILDER</h1>

        <div className="card bg-base-100 shadow-xl mb-12">
        <div className="divider px-8"></div>
  <div className="card-body">

  <h2 className="card-title">Über dich:</h2>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Mindestens 15 Jahre alt</a>
  </div>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Verantwortungsbewusst und Zuverlässig</a>
  </div>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Gute kommunikative Fähigkeiten</a>
  </div>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Bereit Zeit in TTV-Moshpit zu stecken</a>
  </div>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Discord muss vorhanden sein</a>
  </div>

  <div className="divider"></div>

  <h2 className="card-title">Fähigkeiten:</h2>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Kenntnisse über die Minecraft Java Edition (u.a. die neuste Version)</a>
  </div>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Kreative Ideenfindung</a>
  </div>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Fortgeschrittene Kenntnisse von Plugins, wie WorldEdit, VoxelSniper & Co.</a>
  </div>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Talent im bauen von Maps</a>
  </div>

  <div className="divider"></div>

  <h2 className="card-title">Außerdem von Vorteil:</h2>
  <div className="flex items-center gap-1">
  <FaAngleRight/><a>Fähigkeiten im Bereich Game Design</a>
  </div>

  <div className="divider"></div>

  <div className="flex">
  <a>1. Fülle dieses Bewerbungsformular aus</a>
  </div>
  <div className="flex">
  <a>2. Du erhältst eine Einladung zu einem Bewerbungsgespräch welches über Discord stattfindet. Die Einladung und Terminabstimmung erfolgt über Discord.</a>
  </div>
  <div className="flex">
  <a>3. Du bekommst eine 2-wöchige Testphase in der du verschiedene Aufgaben erledigen musst. In dieser Zeit stehen dir natürlich die anderen Teammitglieder bei Problemen zur Seite.</a>
  </div>
  <div className="flex">
  <a>4. Herzlich Willkommen im Team.</a>
  </div>

  <div className="divider"></div>


    <div className="card-actions justify-center">

    <button className="btn bg-secondary hover:bg-base-200 rounded-md" onClick={()=>document.getElementById('BewerbungsFormulardialog').showModal()}>Bewerben</button>

    <form id="BewerbungsFormularform" action="">

    <dialog id="BewerbungsFormulardialog" className="modal modal-bottom sm:modal-middle">
  <div className="modal-box">
    <h3 className="font-bold text-lg mb-3">BewerbungsFormular</h3>

    <div className="space-y-2">
    <label className="input input-bordered flex items-center gap-2">
      <input type="text" className="grow" placeholder="E-Mail" onChange={(e) => {
        const {EMail, value} = e.target;
        console.log(EMail,value);
        setFormData({
          data: {
            ...formData.data,
            EMail: value,
          },
          error: {}
        });

      }}
       />
    </label>
    
    <label className="input input-bordered flex items-center gap-2">
      <input type="text" className="grow" placeholder="Minecraft Name" onChange={(e) => {
        const {MinecraftName, value} = e.target;
        console.log(MinecraftName,value);
        setFormData({
          data: {
            ...formData.data,
            MinecraftName: value,
          },
          error: {}
        });

      }}
       />
    </label>

    <label className="input input-bordered flex items-center gap-2">
      <input type="text" className="grow" placeholder="Discord" onChange={(e) => {
        const {Discord, value} = e.target;
        console.log(Discord,value);
        setFormData({
          data: {
            ...formData.data,
            Discord: value,
          },
          error: {}
        });

      }} />
    </label>

    <label className="flex items-center gap-2">
      <textarea className="textarea textarea-bordered w-full text-base" id="Bewerbung" name="Bewerbung" placeholder="Bewerbung" onChange={(e) => {
        const {Bewerbung, value} = e.target;
        console.log(Bewerbung,value);
        setFormData({
          data: {
            ...formData.data,
            Bewerbung: value,
          },
          error: {}
        });

      }}></textarea>
    </label>

    <div className="flex gap-2 items-center">
        <div>
          <input type="checkbox" id="Angaben" name="Angaben" onChange={() => handleCheckboxChange('AngabenBestätigt')} checked={formData.data.AngabenBestätigt} defaultnotChecked className="checkbox" />
        </div>
          <div>
            <a>Ich bestätige, dass alle Angaben wahrheitsgemäß und ernsthaft ausgefüllt habe</a>
          </div>
      </div>

      <div className="flex gap-2 items-center">
        <div>
          <input type="checkbox" id="Datenschutz" name="Datenschutz" onChange={() => handleCheckboxChange('DatenschutzAkzeptiert')} checked={formData.data.DatenschutzAkzeptiert} defaultnotChecked className="checkbox" />
        </div>
          <div>
            <a>Ich habe die <a className="link link-hover text-secondary" href='/Datenschutzerklärung' target="_blank">Datenschutzerklärung</a> gelesen und stimme dieser zu</a>
          </div>
      </div>

      <div className="">
      <button className="btn bg-secondary hover:bg-base-200 w-full rounded-md" name="Absenden" id="Absenden" disabled={!(formData.data.AngabenBestätigt && formData.data.DatenschutzAkzeptiert)} onClick={sendEmail}>Absenden</button>
      </div>


</div>

<div className="modal-action">
      <form method="dialog">
        
        <button className="btn btn-sm btn-circle btn-ghos absolute right-6 top-6"><FaXmark/></button>
      </form>
    </div>
  </div>
</dialog>
</form>
    
    </div>
    
    
    
    </div>

</div>
</div>



    );
}
export default Builder;