import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Team = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchTeamMembers = async () => {
        try {
            const response = await axios.get('https://admin.ttv-moshpit.de/api/teams?populate=*');
            const responseData = response.data;

            if (responseData && responseData.data) {
                const teamData = responseData.data.map((member) => ({
                    id: member.id,
                    name: member.attributes.Name,
                    description: member.attributes.Beschreibung,
                    imageUrl: member.attributes.Bild?.data?.attributes?.url
                        ? `https://admin.ttv-moshpit.de${member.attributes.Bild.data.attributes.url}`
                        : '',
                    department: member.attributes.Bereich,
                    sortOrder: member.attributes.Sortierung || 0,  
                }));

                setTeamMembers(teamData);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching posts:', error.message);
            setLoading(false);
        }
    };

    const departmentStyles = {
        Administrator: "badge rounded-md badge-lg badge-error font-bold",
        Teamleitung: "badge rounded-md badge-lg badge-warning font-bold",
        Developer: "badge rounded-md badge-lg badge-info font-bold",
        Moderator: "badge rounded-md badge-lg badge-error font-bold",
        Content: "badge rounded-md badge-lg badge-secondary font-bold",
        Designer: "badge rounded-md badge-lg badge-success font-bold",
    };

    const departmentOrder = [ 
        'Administrator',
        'Teamleitung',
        'Developer', 
        'Moderator', 
        'Content', 
        'Designer', 
        'Andere'
    ];

    const groupedByDepartment = teamMembers.reduce((acc, member) => {
        const dept = member.department || 'Andere';
        if (!acc[dept]) acc[dept] = [];
        acc[dept].push(member);
        return acc;
    }, {});

    
    Object.keys(groupedByDepartment).forEach((dept) => {
        groupedByDepartment[dept].sort((a, b) => a.sortOrder - b.sortOrder);
    });

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    return (
        <div className="p-6 w-full max-w-screen-xl mx-auto">
            <h1 className="text-5xl font-bold text-center mt-2 mb-8">TEAM</h1>
            
            {loading ? (
                <p className="text-center text-gray-400">Lädt...</p>
            ) : (
                departmentOrder.map((department) => (
                    groupedByDepartment[department] && (
                        <div key={department} className="mb-12">
                            <div className="flex w-full flex-col items-center mb-6">
                                <div className="flex items-center w-full max-w-3xl">
                                    <hr className="flex-grow border-gray-600" />
                                    <div className={`px-4 mx-4 ${departmentStyles[department] || 'badge rounded-md badge-lg badge-secondary'}`}>
                                        {department}
                                    </div>
                                    <hr className="flex-grow border-gray-600" />
                                </div>
                            </div>

                            <div className="p-6 rounded-md">
                                <div className="flex flex-wrap justify-center gap-6">
                                    {groupedByDepartment[department].map((member) => (
                                        <div 
                                            key={member.id} 
                                            className="w-52 p-3 text-center rounded-md"
                                        >
                                            <div className="flex justify-center items-center mb-3">
                                                {member.imageUrl && (
                                                    <img 
                                                        src={member.imageUrl} 
                                                        alt={member.name} 
                                                        className="w-20 h-20 object-cover rounded-lg"
                                                    />
                                                )}
                                            </div>
                                            <h4 className="text-lg font-bold mb-1">{member.name}</h4>
                                            <p className="text-lg text-secondary mb-2">{member.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                ))
            )}
        </div>
    );
};

export default Team;
